/** @jsx jsx */
import { jsx, Select, Themed } from "theme-ui";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { format, isAfter, subDays } from "date-fns";
import { fetchBlogPosts } from "api";
import commentsIcon from "assets/icons/comments.svg";
import likeIcon from "assets/icons/like_red.svg";
import chevron from "assets/icons/chevron_down.svg";
import Pill from "components/Pill";
import FilterSection from "components/FilterSection";
import Grid from "components/Grid";
import Card from "components/Card";
import InvertableImage from "components/InvertableImage";

const visiblePosts = 18;

export default function BlogPostCardGrid({ posts }) {
  const [blogPostsState, setBlogPostsState] = useState({});
  const [filterState, setFilterState] = useState({});
  const isFilterActive = Object.values(filterState).some(Boolean);
  const [showMobileFilter, setShowMobileFilter] = useState(isFilterActive);
  const [displayLimit, setDisplayLimit] = useState(visiblePosts);
  const postConsideredNew = subDays(new Date(), 14);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await fetchBlogPosts();
        const state = data.blogPostCollection.items.reduce((obj, item) => {
          const {
            likes,
            commentsCollection: { total: comments },
            sys: { id },
          } = item;
          return {
            ...obj,
            [id]: { comments, likes },
          };
        }, {});
        setBlogPostsState(state);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  const filteredPosts = useMemo(() => {
    if (isFilterActive) {
      return posts.filter(post =>
        Boolean(filterState[post.topic.toLowerCase()])
      );
    }
    return posts;
  }, [isFilterActive, filterState, posts]);

  const topics = useMemo(() => {
    return [...new Set(posts.map(post => post.topic))].map(topic => ({
      name: topic,
      uid: topic.toLowerCase(),
    }));
  }, [posts]);

  const resultsHeader = useMemo(() => {
    const total = filteredPosts.length;
    const filtersApplied = Object.keys(filterState).filter(topic =>
      Boolean(filterState[topic])
    );
    let filterString = "";
    if (isFilterActive) {
      filterString = `with ${
        filtersApplied.length === 1
          ? `topic: ${filtersApplied[0]}`
          : `topics: ${filtersApplied.join(", ")}`
      } `;
    }
    if (displayLimit < total) {
      return `Showing ${displayLimit} of ${total} blog posts ${filterString}`;
    }
    return `${
      total === 1 ? "1 blog post" : `${total} blog posts`
    } ${filterString}`;
  }, [displayLimit, filteredPosts]);

  function onClickClearFilter() {
    setFilterState({});
  }

  function onClickFilter(key) {
    setFilterState(state => ({
      ...state,
      [key]: !Boolean(state[key]),
    }));
  }

  function loadMore() {
    setDisplayLimit(displayLimit + visiblePosts);
  }

  function isNewPost(publishedAt) {
    return isAfter(new Date(publishedAt), postConsideredNew);
  }

  return (
    <Fragment>
      <div
        sx={{
          display: [null, null, "flex"],
          flexDirection: ["column", "row"],
          width: "100%",
          flexWrap: ["wrap", "nowrap"],
          gap: "2rem",
          marginBottom: 52,
        }}
      >
        <Pill
          label="Show all"
          ariaLabel="Show all"
          onClick={onClickClearFilter}
          active={!isFilterActive}
          style={{
            marginBottom: "1rem",
            alignSelf: ["flex-start", null, "flex-end"],
          }}
        ></Pill>
        <div sx={{ display: ["flex", "none"], flexDirection: "row" }}>
          <Pill
            label="Filter"
            ariaLabel="Show filter"
            onClick={() => setShowMobileFilter(!showMobileFilter)}
            active={showMobileFilter}
            style={{
              marginBottom: "1rem",
            }}
          />
        </div>

        <div
          sx={{
            display: [showMobileFilter ? "flex" : "none", "flex"],
          }}
        >
          <FilterSection
            filters={topics}
            filterState={filterState}
            header="Filter blog posts by topic:"
            onClickFilter={onClickFilter}
          />
        </div>
        {/*
        <div sx={{ ml: [null, null, "auto"] }}>
          <label
            htmlFor="sort"
            sx={{
              variant: "text.h6",
              fontWeight: 400,
              marginBottom: 20,
              display: "block",
            }}
          >
            Sort blog posts:
          </label>
          <div sx={{ position: "relative", width: [130, null, 200] }}>
            <Select
              id="sort"
              arrow={
                <img
                  alt=""
                  src={chevron}
                  sx={{
                    position: "absolute",
                    right: 24,
                    bottom: "24px",
                    alignSelf: "center",
                    pointerEvents: "none",
                  }}
                />
              }
              defaultValue="Date"
              sx={{
                border: "1px solid var(--theme-ui-colors-text)",
                borderRadius: 4,
                padding: "15px 24px",
                "&:focus": {
                  borderColor: "text",
                },
                marginTop: "auto",
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              <option>Date</option>
            </Select>
          </div>

        </div>
      */}
      </div>
      <Themed.h2
        aria-live="polite"
        sx={{ variant: "text.p", fontWeight: "400" }}
      >
        {resultsHeader}
      </Themed.h2>
      <Grid as="ul" sx={{ p: 0, listStyle: "none" }}>
        {filteredPosts.map(
          (
            {
              cardImage,
              description,
              id,
              likes,
              publishedAt,
              readingTime,
              slug,
              title,
              topic,
            },
            i
          ) =>
            i < displayLimit && (
              <li key={id}>
                <Card
                  body={description}
                  footerComponent={
                    <CardFooter
                      comments={blogPostsState[id]?.comments}
                      likes={blogPostsState[id]?.likes || likes}
                      readingTime={readingTime}
                    />
                  }
                  footerLeft={
                    <span sx={{ color: "var(--theme-ui-colors-lbbBlack)" }}>
                      {format(new Date(publishedAt), "MMM. d. yyyy")}
                    </span>
                  }
                  footerRight={<span sx={{ fontWeight: 500 }}>{topic}</span>}
                  highlightComponent={
                    isNewPost(publishedAt) && (
                      <span
                        sx={{
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          backgroundColor: "var(--theme-ui-colors-lbbBlack)",
                          padding: "10px 36px",
                          color: "white",
                          fontWeight: 500,
                        }}
                      >
                        New post !
                      </span>
                    )
                  }
                  image={cardImage}
                  linkTo={`/blog/${slug}`}
                  style={{ borderBottom: "1px solid" }}
                  title={title}
                />
              </li>
            )
        )}
      </Grid>
      {displayLimit < filteredPosts.length && (
        <Pill
          label={`Load more (${filteredPosts.length - displayLimit})`}
          onClick={loadMore}
          style={{ margin: "60px auto 0" }}
        />
      )}
    </Fragment>
  );
}

const CardFooter = ({ comments = 0, likes = 0, readingTime = 8 }) => (
  <div
    sx={{
      display: "flex",
      justifyContent: "space-between",
      padding: "1rem",
      fontWeight: 500,
      backgroundColor: "#F8F8F8",
      marginTop: "0.5rem",
    }}
  >
    <span>{readingTime.text}</span>
    <div sx={{ display: "flex", gap: 26 }}>
      <div sx={{ display: "flex", gap: "8px" }}>
        <InvertableImage
          src={commentsIcon}
          alt={`${comments} Comments`}
          sx={{ display: "block" }}
        />
        <span aria-hidden>{comments}</span>
      </div>
      <div sx={{ display: "flex", gap: "8px" }}>
        <InvertableImage
          src={likeIcon}
          alt={`${likes} Likes`}
          sx={{ display: "block" }}
        />
        <span aria-hidden>{likes}</span>
      </div>
    </div>
  </div>
);

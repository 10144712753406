/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Link } from "gatsby";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getId } from "content/parsers/ContentfulParsers";
import { fetchBlogPost, addLike } from "api";
import likeIcon from "assets/icons/like.svg";
import likeIconWhite from "assets/icons/like_white.svg";
import likeIconRed from "assets/icons/like_red.svg";
import Layout from "components/Layout";
import { H1, SubHeader } from "components/AuthLayouts";
import Separator from "components/Separator";
import BlogPostCardGrid from "components/BlogPostCardGrid";
import RichText from "components/RichText";
import EmailButton from "components/EmailButton";
import CopyURLButton from "../components/CopyURLButton";
import FacebookButton from "components/FacebookButton";
import PinterestButton from "components/PinterestButton";
import Pill from "components/Pill";
import CommentsSection from "components/CommentsSection";
import ThumbnailLightboxGrid from "components/ThumbnailLightboxGrid";

export default function BlogPost({
  pageContext: {
    post: {
      author,
      body,
      brailleTextImage,
      cardImage,
      commentsCollection = [],
      description,
      id,
      images,
      likes: noOfLikes,
      publishedAt,
      readAlso,
      readingTime,
      seo,
      slug,
      title,
      topic,
    },
    posts = [],
  },
  location,
}) {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [likes, setLikes] = useState(noOfLikes);
  const [didLikePost, setDidLikePost] = useState(false);
  const [announceLike, setAnnounceLike] = useState(false);
  const [comments, setComments] = useState(commentsCollection);
  const [didAddComment, setDidAddComment] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setDidLikePost(Boolean(localStorage.getItem(id)));
        const { data } = await fetchBlogPost({ id });
        setLikes(data.blogPost.likes ?? 0);
        setComments(data.blogPost.commentsCollection.items);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (didAddComment) {
      const { id } = comments[comments.length - 1].sys;
      const screenReaderTextElement = document.createElement("span");
      screenReaderTextElement.className = "sr-only";
      screenReaderTextElement.setAttribute("role", "alert");
      screenReaderTextElement.textContent =
        "Your comment was successfully submitted";
      const element = document.getElementById(id);
      element.appendChild(screenReaderTextElement);
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setDidAddComment(false);
    }
  }, [comments, didAddComment]);

  async function handleLikePost() {
    try {
      setDidLikePost(true);
      setLikes(state => (state += 1));
      setAnnounceLike(true);
      localStorage.setItem(id, "true");
      await addLike({ id });
    } catch (e) {
      console.error(e);
    }
  }

  function handleAddComment(comment) {
    setComments(comments => [...comments, comment]);
    setDidAddComment(true);
  }

  function readAlsoPath() {
    if (readAlso.sys.contentType.sys.id === "facilitationArticle") {
      return `/facilitation/${getId(readAlso.fields.title)}`;
    } else if (readAlso.sys.contentType.sys.id === "activity") {
      const { activityGroup, title } = readAlso.fields;
      return `/activities/${activityGroup.fields.id}/${getId(title)}`;
    } else {
      return `/blog/${readAlso.fields.slug}`;
    }
  }

  async function goToLogin() {
    try {
      await instance.loginRedirect({});
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Layout
      brailleTextImage={brailleTextImage}
      breadcrumbsMap={{ [slug]: title }}
      description={seo.description}
      title={seo.title || title}
      imageUrl={cardImage.url}
    >
      <div
        sx={{
          minWidth: [null, null, 740],
          maxWidth: [null, null, "50%"],
          margin: "auto",
        }}
      >
        <article sx={{ mb: 85 }}>
          <H1>{title}</H1>
          <div sx={{ display: "flex", gap: 24, mb: 24 }}>
            <span sx={{ fontWeight: 500 }}>{topic}</span>
            <span
              sx={{
                width: "2px",
                height: "24px",
                backgroundColor: "rgba(51,51,51,0.1)",
              }}
            ></span>
            <span>{readingTime.text}</span>
          </div>
          <SubHeader style={{ marginBottom: 41 }}>{description}</SubHeader>
          <Themed.p
            sx={{ fontWeight: 500, mb: "8px" }}
          >{`Written by ${author}`}</Themed.p>
          <Themed.p
            sx={{
              color: "var(--theme-ui-colors-gray)",
              fontWeight: 500,
              m: 0,
            }}
          >
            {format(new Date(publishedAt), "MMM. d. yyyy")}
          </Themed.p>
          <img
            src={cardImage.url}
            alt={cardImage.alt}
            sx={{ maxWidth: "100%", height: "auto", margin: "40px 0" }}
            object-fit="cover"
          />
          <RichText render={body} />
          <ThumbnailLightboxGrid
            images={images}
            title={seo.title || title}
            useImageTexts
          />
          {Boolean(readAlso) && (
            <div
              sx={{
                display: "flex",
                gap: 24,
                borderTop: "1px solid var(--theme-ui-colors-lbbBlack)",
                borderBottom: "1px solid var(--theme-ui-colors-lbbBlack)",
                padding: "24px 0",
                mt: 40,
                "@media print": { display: "none" },
              }}
            >
              <Themed.h6 sx={{ fontWeight: 500 }}>Also read:</Themed.h6>
              <Link
                to={readAlsoPath()}
                sx={{
                  color: "lbbRed",
                  fontSize: 1,
                  fontWeight: 500,
                  lineHeight: "28px",
                  textDecoration: "none",
                }}
              >
                {readAlso.fields.title}
              </Link>
            </div>
          )}
        </article>
        <div sx={{ mb: 64, "@media print": { display: "none" } }}>
          <Themed.h2 sx={{ variant: "text.h4", marginBottom: "30px" }}>
            Share this blog post
          </Themed.h2>
          <ul
            sx={{
              listStyleType: "none",
              padding: 0,
              display: "flex",
              flexDirection: ["column", "row"],
              flexWrap: "wrap",
              "> *": {
                marginRight: [0, "1rem"],
                marginBottom: "1rem",
              },
            }}
          >
            <li>
              <EmailButton emailBody={location.href} />
            </li>
            <li>
              <FacebookButton url={location.href} />
            </li>
            <li>
              <PinterestButton
                description={title}
                imageUrl={cardImage.url}
                url={location.href}
              />
            </li>
            <li>
              <CopyURLButton url={location.href} />
            </li>
          </ul>
        </div>
        <div
          sx={{
            mb: 64,
            pl: 23,
            borderLeft: "2px solid var(--theme-ui-colors-lbbRed)",
            "--theme-ui-colors-text": didLikePost
              ? "var(--theme-ui-colors-lbbRed)"
              : "inherit",
            "--theme-ui-colors-background": didLikePost ? "white" : "inherit",
            "@media print": { display: "none" },
          }}
        >
          <Themed.h2
            sx={{ variant: "text.h4", marginBottom: "30px" }}
            role={announceLike ? "alert" : undefined}
          >
            {didLikePost
              ? `you and ${likes} others like this blog post`
              : `${
                  likes === 1 ? `${likes} person likes` : `${likes} people like`
                } this blog post`}
          </Themed.h2>
          <Pill
            disabled={didLikePost}
            label="Like blog post"
            icon={{
              url: didLikePost ? likeIconRed : likeIconWhite,
              activeUrl: likeIcon,
              width: 18,
              height: 17,
            }}
            onClick={handleLikePost}
            style={{
              border: "none",
              boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)",
            }}
          />
        </div>
        <CommentsSection
          addComment={handleAddComment}
          comments={comments}
          container="blog post"
          entryId={id}
          title="Comments"
        />
        {!isAuthenticated && (
          <section
            sx={{
              backgroundColor: "var(--theme-ui-colors-lbbYellow)",
              padding: "20px 36px",
              textAlign: "center",
              mb: "auto",
            }}
          >
            <Themed.h4>Login to post your comment!</Themed.h4>
            <Themed.p sx={{ mb: 4 }}>
              Login to your existing profile or create a new profile in order to
              leave a comment on this activity.
            </Themed.p>
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row", null],
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                mb: 35,
              }}
            >
              <Pill
                label="Login or create a new user profile"
                onClick={goToLogin}
                type="button"
              ></Pill>
            </div>
          </section>
        )}
      </div>

      <div sx={{ "@media print": { display: "none" } }}>
        <Separator />
        <Themed.h2>More posts</Themed.h2>
        <BlogPostCardGrid posts={posts.filter(post => post.id !== id)} />
      </div>
    </Layout>
  );
}

/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import React from "react";

export function MainSection({ children, style }) {
  return (
    <section sx={{ width: [null, null, "50%"], ...style }}>{children}</section>
  );
}

export function SecondarySection({ children, style }) {
  return (
    <section
      sx={{
        display: "flex",
        flexDirection: ["column", null, "row"],
        justifyContent: "space-between",
        alignItems: "center",
        padding: ["50px 0", null, "60px 56px"],
        width: [null, null, "50%"],
        backgroundColor: "var(--theme-ui-colors-lbbYellow)",
        ...style,
      }}
    >
      {children}
    </section>
  );
}

export const H1 = props => (
  <Themed.h1 sx={{ marginBottom: [3, null, "22px"] }} {...props} />
);

export const SubHeader = ({ style, ...rest }) => (
  <Themed.p
    sx={{
      fontWeight: 300,
      marginBottom: ["24px", null, 4],
      lineHeight: 2,
      fontSize: 2,
      ...style,
    }}
    {...rest}
  />
);
